export const ENTITY_TYPES = {
  PROJECT: 1,
  COMPANY: 2,
  CONTACT: 3,
  TENDER: 4,
  ELEMENT: 5,
  ACTIVITY: 6,
  NOTE: 7,
  //
  CLIENT: 8,
  USER: 9,
  API_CLIENT: 10,
  FILE: 11,
  RELEASE_NOTE: 12,
  WEBHOOK: 13,
  WEBHOOK_QUEUE: 14,
  EMAIL_LOG: 15,
  SAVED_SEARCH_NOTIFICATION: 16,
  USER_TRANSFER_TASK: 17,
  EMAIL_LOG_EVENT: 18,
  USER_TRANSFER_TASK_STAGE: 19,
  COMPONENT: 20,
  MODULE: 21,
  MODULE_ACCOUNT_CONNECTION: 22,
  DEAD_MESSAGE: 23,
  DEAD_MESSAGE_SUBSCRIPTION: 24,
  DEFAULT_FIELDSET: 25,
  VORTAL_CONTACT: 26,
  VORTAL_COMPANY: 27,
  VORTAL_PROJECT: 28,
  VORTAL_NOTIFICATION_EMAIL: 29,
  VORTAL_LOG: 30,
  MYRAN_CLIENT_VERSION: 31,
  MYRAN_SUBSCRIPTION_VERSION: 32,
  MYRAN_SUSPENDED_USER: 33,
  MYRAN_USER_PROFILE: 34,
  MYRAN_USER_VERSION: 35,
  USAGE_STATS_USER: 36,
  USAGE_STATS_CLIENT: 37,
  CUSTOM_FIELD_METRIC: 38,
  CUSTOM_FIELD_SELECTION_METRIC: 39,
  PM_CLIENT: 40,
  PM_CLIENT_VERSION: 41,
  PM_PROJECT: 42,
  PM_COMPANY: 43,
  PM_CONTACT: 44,
  PM_DOCUMENT: 45,
  PM_EMPLOYEE: 46,
  PM_ACTIVITY: 47,
  PM_CUSTOM_FIELD: 48,
  PM_CUSTOM_FIELD_VALUE: 49,
  PM_CUSTOM_FIELD_SELECTION: 50,
  PM_CUSTOM_FIELD_EMPLOYEE: 51,
  CUSTOM_FIELDSET: 52,
  ERROR_MESSAGE: 53,
  SUBSCRIPTION: 54,
  CLIENT_USER: 55,
  SMART_SELECTION: 56,
  USER_PROFILE: 57,
  LICENSE: 58,
  COUNTRY_MODULES: 59,
};

export const SOURCE_TYPES = {
  RSM: 1,
  PAR: 2,
  TED: 3,
  NCIS: 4,
  USER: 5,
  MYRAN_SMART: 6,
  MYRAN_FNL: 7,
  SMART: 8,
  MYRAN_MSB: 9,
  MYRAN_MNB: 10,
  XPLORER: 11,
  MYRAN_PARABYG: 12,
  OLMERO: 13,
  SHARP: 14,
  properties: {
    1: { name: 'Rsm' },
    2: { name: 'Par' },
    3: { name: 'Ted' },
    4: { name: 'Ncis' },
    5: { name: 'User' },
    6: { name: 'Myran' },
    7: { name: 'Myran FNL' },
    8: { name: 'Smart' },
    9: { name: 'Myran MSB' },
    10: { name: 'Myran MNB' },
    11: { name: 'Xplorer' },
    12: { name: 'Myran parabyg' },
    13: { name: 'Olmero' },
    14: { name: 'Sharp' },
  },
};

export const VISIBILITY = {
  OWNER_ONLY: 1,
  OWNER_ORGANIZATION: 2,
  PUBLIC: 3,
};

export const GEO_ACCURACY = {
  RSM_MANUAL: 1,
  ROOFTOP: 2,
  RANGE_INTERPOLATED: 3,
  GEOMETRIC_CENTER: 4,
  APPROXIMATE: 5,
  RSM_CITY_CENTER: 6,
};

export const DATE_ACCURACY = {
  EXACT_DAY: 1,
  MONTH: 2,
  MONTH_RANGE: 3,
  QUARTER: 4,
  SEASON: 5,
  HALF_YEAR: 6,
  YEAR: 7,
  TEAR_RANGE: 8,
  WEEK: 9,
  DAY_RANGE: 10,
  EXACT_TIME: 11,
};

export const MENU_TYPES = {
  STATIC: 1,
  USER_PROJECT_FOLDERS: 2,
  CLIENT_COMPANY_FOLDERS: 3,
};

export const ACC_SETTING_TYPE = {
  LICENSE: 1,
  CLIENT: 2,
  CLIENT_LICENSE: 3,
  CLIENT_USER_LICENSE: 4,
  // PACKAGE: 5,
  USER: 6,
  CLIENT_FIXED: 7,
  USER_FIXED: 8,
  AC_FIXED: 9,
  AC: 10,
};

export const USER_STATUSES = {
  DUMMY: 1,
  INIT: 2,
  LIVE: 3,
};

export const SMART_USER_STATUSES = {
  INIT: 2,
  LIVE: 3,
};

export const USER_TOKEN_TYPES = {
  MAGIC_LINK: 1,
  RESET_PASSWORD: 2,
  INVITE: 3,
  REDIRECT_LINK: 4,
};

export const CLIENT_ROLE_TYPES = {
  CLIENT_ROLE: 1,
  PARTNER_ROLE: 2,
  PROJECT_TRIGGER_RESTRICTION_ROLE: 3,
  TENDER_TRIGGER_RESTRICTION_ROLE: 4,
};

export const LICENSE_QUOTA_TYPES = {
  PACKAGE_LICENSE: 1,
  CLIENT_LICENSE: 2,
};

export const CONTACT_TYPES = {
  EMAIL: 1,
  PHONE: 2,
};

export const ADDRESS_TYPES = {
  MAILING_ADDRESS: 1,
  VISITING_ADDRESS: 2,
};

export const INVITE_STATUSES = {
  NOT_SENT: 1,
  SENDING: 2,
  SENT: 3,
};

export const NOTIF_GATEWAYS = {
  EMAIL: 1,
  SMS: 2,
  APP: 3,
};

export const NOTIF_QUEUE_STATUES = {
  QUEUING: 1,
  SENDING: 2,
  SENT: 3,
  NOT_SENT: 4,
  CANCELED: 5,
  PAUSED: 6,
  UPDATING: 7,
  SUBJECT_DELETED: 8,
};

export const SENDING_MODES = {
  IMMEDIATELY: 1,
  DAILY: 2,
  WEEKLY: 3,
  DATETIME: 4,
};

export const NOTIF_STATUSES = {
  DELIVERED: 1,
  APPLIED: 2,
  ABORTED: 3,
  BELATED: 4,
};


export const USER_PROJECT_STATUSES = {
  NEW: 1,
  WORKING_WITH: 2,
  MONITORING: 3,
  UNDESIRABLE: 4,
  FINISHED: 5,
  IMPORTED: 6,
  properties: {
    1: {
      key: 'new',
      icon: 'attention-round',
      coworkerIcon: 'attention-round',
      boldIcon: 'status-new-bold',
      isSystem: true,
    },
    2: {
      key: 'working_with',
      icon: 'working_with',
      coworkerIcon: 'coworker-status-working-with',
      boldIcon: 'status-working-with-bold',
    },
    3: {
      key: 'monitoring',
      icon: 'monitoring',
      coworkerIcon: 'coworker-status-monitoring',
      boldIcon: 'status-monitoring-bold',
    },
    4: {
      key: 'undesirable',
      icon: 'undesirable',
      coworkerIcon: 'coworker-status-undesirable',
      boldIcon: 'status-undesirable-bold',
      unimportant: true,
    },
    5: {
      key: 'finished',
      icon: 'finished',
      coworkerIcon: 'coworker-status-finished',
      boldIcon: 'status-finished-bold',
      unimportant: true,
    },
    6: {
      key: 'imported',
      icon: 'imported',
      name: 'Imported',
      coworkerIcon: 'coworker-status-imported',
      boldIcon: 'status-imported-bold',
      conditional: true,
    },
  },
};

export const USER_TENDER_STATUSES = {
  NEW: 1,
  WORKING_WITH: 2,
  MONITORING: 3,
  UNDESIRABLE: 4,
  FINISHED: 5,
  properties: {
    1: {
      key: 'new',
      icon: 'attention-round',
      coworkerIcon: 'attention-round',
      boldIcon: 'status-new-bold',
      isSystem: true,
    },
    2: {
      key: 'working_with',
      icon: 'working_with',
      coworkerIcon: 'coworker-status-working-with',
      boldIcon: 'status-working-with-bold',
    },
    3: {
      key: 'monitoring',
      icon: 'monitoring',
      coworkerIcon: 'coworker-status-monitoring',
      boldIcon: 'status-monitoring-bold',
    },
    4: {
      key: 'undesirable',
      icon: 'undesirable',
      coworkerIcon: 'coworker-status-undesirable',
      boldIcon: 'status-undesirable-bold',
      unimportant: true,
    },
    5: {
      key: 'finished',
      icon: 'finished',
      coworkerIcon: 'coworker-status-finished',
      boldIcon: 'status-finished-bold',
      unimportant: true,
    },
  },
};

export const ACTIVITY_STATUSES = {
  OVERDUE: 1,
  PLANNED_TODAY: 2,
  PLANNED_LATER: 3,
  DONE: 4,
  properties: {
    1: { key: 'overdue', icon: 'activity-overdue-missed', class: 'overdue', priority: 3 },
    2: { key: 'planned_today', icon: 'activity-clock', class: 'planned-today', priority: 1 },
    3: { key: 'planned_later', icon: 'activity-clock', class: 'planned-later', priority: 2 },
    4: { key: 'done', icon: 'activity-overdue-finished', class: 'done', priority: 4 },
  },
};

export const ACTIVITY_TYPES = {
  CALL: 1,
  MEETING: 2,
  TASK: 3,
  properties: {
    1: { key: 'call', icon: 'activity-call' },
    2: { key: 'meeting', icon: 'activity-meeting' },
    3: { key: 'task', icon: 'activity-time' },
  },
};

export const ADMINISTRATIVE_UNIT_TYPES = {
  COUNTRY: 1,
  REGION: 2,
  DISTRICT: 3,
};

export const CLIENT_COMPANY_FOLDER_TYPES = {
  PROSPECT: 1,
  CLIENT: 2,
  COMPETITOR: 3,
  CUSTOM: 4,
};

export const CLIENT_COMPANY_FOLDERS = {
  PROSPECT: 'prospects',
  CLIENT: 'clients',
  COMPETITOR: 'competitors',
  CUSTOM: 'custom',
  properties: {
    prospects: {
      icon: 'folder-prospects-outline',
      coworkerIcon: 'folder-prospects',
      boldIcon: 'folder-prospects-bold',
    },
    clients: {
      icon: 'folder-clients-outline',
      coworkerIcon: 'folder-clients',
      boldIcon: 'folder-clients-bold',
    },
    competitors: {
      icon: 'folder-competitors-outline',
      coworkerIcon: 'folder-competitors',
      boldIcon: 'folder-competitors-bold',
    },
    custom: {
      icon: 'folder-custom-outline',
      coworkerIcon: 'folder-custom',
      boldIcon: 'folder-custom-bold',
    },
  },
};

export const COMMUNICATION_TYPES = {
  PHONE: 1,
  MOBILE: 2,
  FAX: 3,
  EMAIL: 4,
  WEBSITE: 5,
  SKYPE: 6,
  properties: {
    1: { key: 'phone', icon: 'phone' },
    2: { key: 'mobile', icon: 'phone-mobile' },
    3: { key: 'fax', icon: 'phone-fax' },
    4: { key: 'email', icon: 'email' },
    5: { key: 'website', icon: 'web' },
    6: { key: 'skype', icon: '' },
  },
};

export const RESIDENCE_FORMS = {
  LEASEHOLD: 1,
  TENANT_OWNERSHIP: 2,
};

export const LIST_TYPES = {
  ALL: 0,
  ALL_PROJECTS: 1,
  ALL_COMPANIES: 2,
  ALL_CONTACTS: 3,
  ALL_TENDERS: 4,
  USER_PROJECTS: 5,
  USER_COMPANIES: 6,
  USER_CONTACTS: 7,
  USER_TENDERS: 8,
  USER_ACTIVITIES: 9,
  USER_NOTES: 10,
  PROJECT_TRIGGER: 11,
  TENDER_TRIGGER: 12,
  PROJECT_COMPANIES: 13,
  PROJECT_TENDERS: 14,
  COMPANY_PROJECTS: 15,
  COMPANY_CONTACTS: 16,
  COMPANY_TENDERS: 17,
  CONTACT_PROJECTS: 18,
  CONTACT_TENDERS: 19,
  USER_FILES: 20,
  ALL_FILES: 21,
  ALL_CLIENTS: 22,
  CLIENT_USERS: 23,
  ALL_USERS: 24,
  CLIENT_SELECTIONS: 25,
  ALL_API_CLIENTS: 26,
  TENDER_TENDERS: 27,
  //
  ALL_COMPONENTS: 28,
  ALL_DEFAULT_FIELDSETS: 29,
  ALL_MODULE_ACCOUNT_CONNECTIONS: 30,
  ALL_MODULES: 31,
  USAGE_STATISTICS_CLIENTS: 32,
  USAGE_STATISTICS_USERS: 33,
  MYRAN_LOGS_CLIENT_VERSIONS: 34,
  MYRAN_LOGS_SUBSCRIPTION_VERSIONS: 35,
  MYRAN_LOGS_SUSPENDED_USERS: 36,
  MYRAN_LOGS_USER_PROFILES: 37,
  MYRAN_LOGS_USER_VERSIONS: 38,
  ALL_WEBHOOKS: 39,
  USER_WEBHOOKS: 40,
  ALL_WEBHOOKS_QUEUE: 41,
  SUBSCRIPTION_MYRAN_UPDATES: 42,
  ALL_RELEASE_NOTES: 43,
  VORTAL_CONNECT_CONTACTS: 44,
  VORTAL_CONNECT_COMPANIES: 45,
  VORTAL_CONNECT_PROJECTS: 46,
  VORTAL_CONNECT_NOTIFICATION_EMAILS: 47,
  VORTAL_CONNECT_LOGS: 48,
  ALL_EMAIL_LOGS: 49,
  USER_EMAIL_LOGS: 50,
  USER_MYRAN_UPDATES: 51,
  ALL_EMAIL_LOG_EVENTS: 52,
  ALL_SAVED_SEARCH_NOTIFICATIONS_LIST: 53,
  ALL_USER_TRANSFER_TASKS: 54,
  USER_TRANSFER_TASKS: 55,
  USER_WEBHOOKS_QUEUE: 56,
  DEAD_MESSAGES: 57,
  CITYMARK_LEADS: 58,
  DEAD_MESSAGE_SUBSCRIPTIONS: 59,
  USER_TRANSFER_TASK_STAGES: 60,
  ALL_CUSTOM_FIELD_METRICS: 61,
  ALL_CUSTOM_FIELD_SELECTION_METRICS: 62,
  ALL_PM_CLIENTS: 63,
  ALL_PM_CLIENT_VERSIONS: 64,
  ALL_PM_PROJECTS: 65,
  ALL_PM_COMPANIES: 66,
  ALL_PM_CONTACTS: 67,
  ALL_PM_DOCUMENTS: 68,
  ALL_PM_EMPLOYEES: 69,
  ALL_PM_ACTIVITIES: 70,
  ALL_PM_CUSTOM_FIELDS: 71,
  ALL_PM_CUSTOM_FIELD_VALUES: 72,
  ALL_PM_CUSTOM_FIELD_SELECTIONS: 73,
  ALL_PM_CUSTOM_FIELD_EMPLOYEES: 74,
  ALL_CUSTOM_FIELDSET_TEMPLATES: 75,
  ERROR_MESSAGES: 76,
  SUBSCRIPTIONS: 77,
  CLIENT_SUBSCRIPTIONS: 78,
  SUBSCRIPTION_USERS: 79,
  USER_SUBSCRIPTIONS: 80,
  LICENSES: 81,
  DASHBOARD_WIDGETS: 82,
  COUNTRY_MODULES: 83,
};

export const TENDER_SUBJECTS = {
  MATERIALS: 1,
  ROLE: 2,
  properties: {
    1: { key: 'materials', icon: 'material' },
    2: { key: 'role', icon: 'role_contractor' },
  },
};

export const PROJECT_STAGES = {
  IDEA: 1,
  PROJECTING: 2,
  PROCUREMENT: 3,
  CONSTRUCTION: 4,
};

export const COUNTRIES = {
  SWEDEN: 1,
  DENMARK: 2,
  FINLAND: 3,
  NORWAY: 4,
  CZECH: 63,
  SLOVAKIA: 202,
  SWITZERLAND: 215,
  LIECHTENSTEIN: 129,
  properties: {
    1: {
      isoCode: 'se',
      capitalCoordinates: { lat: 59.323295, lng: 18.070069 },
      img: '/img/flags/se.svg',
    },
    2: {
      isoCode: 'dk',
      capitalCoordinates: { lat: 55.676000, lng: 12.568300 },
      img: '/img/flags/dk.svg',
    },
    3: {
      isoCode: 'fi',
      capitalCoordinates: { lat: 60.171200, lng: 24.932600 },
      img: '/img/flags/fi.svg',
    },
    4: {
      isoCode: 'no',
      capitalCoordinates: { lat: 59.913800, lng: 10.752240 },
      img: '/img/flags/no.svg',
    },
    63: {
      isoCode: 'cz',
      capitalCoordinates: { lat: 50.073658, lng: 14.418540 },
      img: '/img/flags/cz.svg',
    },
    202: {
      isoCode: 'sk',
      capitalCoordinates: { lat: 48.148500, lng: 17.107747 },
      img: '/img/flags/sk.svg',
    },
    215: {
      isoCode: 'ch',
      capitalCoordinates: { lat: 46.947456, lng: 7.451123 },
      img: '/img/flags/ch.svg',
    },
    129: {
      isoCode: 'li',
      capitalCoordinates: { lat: 47.145100, lng: 9.518400 },
      img: '/img/flags/li.svg',
    },
  },
};

export const TENDER_DATE_TYPES = {
  FIRST_DAY_DOCUMENT: 1,
  LAST_DAY_DOCUMENT: 2,
  LAST_DAY_QUESTIONS: 3,
  LAST_TENDER_DAY: 4,
  PRE_QUALIFICATION: 5,
};

export const CONTRACT_TYPES = {
  DESIGN_AND_CONSTRUCT_CONTR: 1,
  DELIVERY_CONTRACTOR: 2,
  OTHER: 3,
};

export const CURRENCIES = {
  SEK: 1,
  DKK: 2,
  EUR: 3,
  NOK: 4,
  CZK: 5,
};

export const ECOCERTIFIED_TYPES = {
  YES: 1,
  NO: 2,
  UNKNOWN: 3,
};

export const CONTACT_ALL_ROLES_INDEXES = {
  PROJECT_ID: 0,
  COMPANY_ID: 1,
  COMPANY_ROLE_ID: 2,
  CONTACT_ID: 3,
  CONTACT_ROLE_ID: 4,
};

export const ENV_MODES = {
  LOCALHOST: 'dev',
  STAGE: 'stg',
  PRODUCTION: 'prod',
};

export const USER_COMPANY_FOLDERS = {
  PROSPECTS: -1,
};

export const SERVER_ERROR_CODES = {
  ACCEPTED: 202,
  BAD_REQUEST: 400,
  NOT_NULL: 401,
  EMAIL_ADDRESS: 402,
  MATCH_OF_ENUM: 403,
  INVALID_FIELD: 404,
  INTERNAL_SERVER_ERROR: 500,
  UNAUTHORIZED: 501,
  UNAVAILABLE: 503,
  INVALID_CREDENTIALS: 600,
  INVALID_USERNAME: 601,
  INVALID_PASSWORD: 602,
  INVALID_TOKEN: 603,
  NOT_FOUND: 604,
  ALREADY_EXISTS: 605,
  FORBIDDEN: 606,
  USER_SUSPENDED: 607,
  COMPANY_SUBSCRIPTION_EXPIRED: 608,
  USER_LICENSE_EXPIRED: 609,
  CLIENT_NOT_ACTIVE: 610,
  USER_NOT_ACTIVE: 611,
  USER_IS_BANNED: 612,
  EXTERNAL_REQUEST_ERROR: 613,
  FILE_TOO_LARGE: 614,
  WRONG_FILE_TYPE: 615,
  PASSWORD_LOGIN_REQUIRED: 50101,
  WRONG_AZURE_OID: 50102,
  MAINTENANCE_MODE: 50301,
  properties: {
    501: { apiRedirected: true },
    608: { apiRedirected: true },
    609: { apiRedirected: true },
    610: { apiRedirected: true },
    611: { apiRedirected: true },
    607: { apiRedirected: true },
    500: { apiRedirected: true },
  },
};

export const SEARCHABLE_LIST_TYPES = {
  USERS: 1,
  CLIENTS: 2,
};

export const LICENSES = {
  SUPPORT: 1,
  ADMIN: 2,
  USER: 3,
  DEVELOPER: 4,
  // TENDERS_ONLY: 5,
  SMART_API_CLIENT: 6,
  STRATEG_ANALYS_API_CLIENT: 7,
  // LEADS_OFF: 8,
};

export const TRIGGER_TYPES = {
  PROJECT: 1,
  TENDER: 2,
  COMPANY: 3,
  CONTACT: 4,
};

export const DICT_SECTION_TYPES = {
  VOCABULARY: 'vocabularies',
  LOOKUPS: 'lookups',
};

export const ACTIVITY_NOTES_TYPES = {
  ACTIVITY: 1,
  NOTE: 2,
};

export const NOTE_ACTIONS = {
  EDIT: 1,
  DELETE: 2,
  PINED: 3,
};

export const ACTIVITY_ACTIONS = {
  EDIT: 1,
  DELETE: 2,
};

export const ENTITY_LIST_VIEWS = {
  LIST: 'table',
  CARD: 'cards',
  MAP: 'map',
};

export const ENTITY_LIST_PAGINATION_TYPES = {
  BUTTONS: 'buttons',
  COLLAPSED: 'collapsed',
};

export const ENTITY_LIST_LOAD_TYPES = {
  EXPORT_EXCEL: 1,
  PRINT: 2,
};

export const CRAFT_STATES = {
  ASSIGNED: 1,
  UNASSIGNED: 2,
};

export const DOC_ACCESS_TYPES = {
  ORIGINAL: 1,
  STORED: 2,
};

export const USER_IMPORT_STATUSES = {
  ADDED: 1,
  IGNORED: 2,
  SUSPENDED: 3,
  UNSUSPENDED: 4,
  EXISTING: 5,
  REPLACE: 6,
};

export const TABLE_FIELD_TYPES = {
  JSON: 1,
};

export const QUERY_DATA_TYPES = {
  DATE: 1,
  DATE_RANGE: 2,
  DATETIME: 3,
  DATETIME_RANGE: 4,
  LTE_GTE: 5,
  IN: 6,
  EQ: 7,
  Q: 8,
};

export const ACCOUNT_ACCESS_TYPES = {
  USER: 1,
  SUPPORT: 2,
  CLIENT: 3,
  API_CLIENT: 4,
};

export const LOOKUP_SECTION_TYPES = {
  APP: 1,
  API: 2,
};

export const LANGUAGES = {
  ENGLISH: 1,
  SWEDISH: 2,
  DANISH: 3,
  FINNISH: 4,
  NORWEGIAN: 5,
  CZECH: 6,
  SLOVAK: 7,
  GERNAN: 8,
  FRENCH: 9,
  ITALIAN: 10,
  properties: {
    1: {
      iso: 'en',
      intlIsoVars: ['en'],
      img: '/img/flags/gb.svg',
    },
    2: {
      iso: 'sv',
      domain: 'se',
      intlIsoVars: ['sv'],
      img: '/img/flags/se.svg',
    },
    3: {
      iso: 'da',
      domain: 'dk',
      intlIsoVars: ['da'],
      img: '/img/flags/dk.svg',
    },
    4: {
      iso: 'fi',
      domain: 'fi',
      intlIsoVars: ['fi'],
      img: '/img/flags/fi.svg',
    },
    5: {
      iso: 'no',
      domain: 'no',
      intlIsoVars: ['no', 'nb'],
      // intlIsoVars: ['no', 'nb', 'nn'],
      img: '/img/flags/no.svg',
    },
    6: {
      iso: 'cs',
      domain: 'cz',
      intlIsoVars: ['cs'],
      img: '/img/flags/cz.svg',
    },
    7: {
      iso: 'sk',
      domain: 'sk',
      intlIsoVars: ['sk'],
      img: '/img/flags/sk.svg',
    },
    8: {
      iso: 'de',
      intlIsoVars: ['de', 'dd'],
      img: '/img/flags/de.svg',
    },
    9: {
      iso: 'fr',
      intlIsoVars: ['fr', 'fx'],
      img: '/img/flags/fr.svg',
    },
    10: {
      iso: 'it',
      intlIsoVars: ['it', 'ita'],
      img: '/img/flags/it.svg',
    },
  },
};

export const US_DATE_RANGE_TYPES = {
  WEEKLY_DATA: 1,
  MONTHLY_DATA: 2,
  ANNUAL_DATA: 3,
  CUSTOM_RANGE_DATA: 4,
  properties: {
    1: { key: 'weekly', periodDays: 6 },
    2: { key: 'monthly', periodDays: 29 },
    3: { key: 'annual', periodDays: 364 },
    4: { key: 'custom', periodDays: null },
  },
};

export const KEYCODE = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  PAUSE: 19,
  CAPS_LOCK: 20,
  ESCAPE: 27,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  INSERT: 45,
  DELETE: 46,
  KEY_0: 48,
  KEY_1: 49,
  KEY_2: 50,
  KEY_3: 51,
  KEY_4: 52,
  KEY_5: 53,
  KEY_6: 54,
  KEY_7: 55,
  KEY_8: 56,
  KEY_9: 57,
  KEY_A: 65,
  KEY_B: 66,
  KEY_C: 67,
  KEY_D: 68,
  KEY_E: 69,
  KEY_F: 70,
  KEY_G: 71,
  KEY_H: 72,
  KEY_I: 73,
  KEY_J: 74,
  KEY_K: 75,
  KEY_L: 76,
  KEY_M: 77,
  KEY_N: 78,
  KEY_O: 79,
  KEY_P: 80,
  KEY_Q: 81,
  KEY_R: 82,
  KEY_S: 83,
  KEY_T: 84,
  KEY_U: 85,
  KEY_V: 86,
  KEY_W: 87,
  KEY_X: 88,
  KEY_Y: 89,
  KEY_Z: 90,
  LEFT_META: 91,
  RIGHT_META: 92,
  SELECT: 93,
  NUMPAD_0: 96,
  NUMPAD_1: 97,
  NUMPAD_2: 98,
  NUMPAD_3: 99,
  NUMPAD_4: 100,
  NUMPAD_5: 101,
  NUMPAD_6: 102,
  NUMPAD_7: 103,
  NUMPAD_8: 104,
  NUMPAD_9: 105,
  MULTIPLY: 106,
  ADD: 107,
  SUBTRACT: 109,
  DECIMAL: 110,
  DIVIDE: 111,
  F1: 112,
  F2: 113,
  F3: 114,
  F4: 115,
  F5: 116,
  F6: 117,
  F7: 118,
  F8: 119,
  F9: 120,
  F10: 121,
  F11: 122,
  F12: 123,
  NUM_LOCK: 144,
  SCROLL_LOCK: 145,
  SEMICOLON: 186,
  EQUALS: 187,
  COMMA: 188,
  DASH: 189,
  PERIOD: 190,
  FORWARD_SLASH: 191,
  GRAVE_ACCENT: 192,
  OPEN_BRACKET: 219,
  BACK_SLASH: 220,
  CLOSE_BRACKET: 221,
  SINGLE_QUOTE: 222,
};

export const USER_STATUS_STEPS = {
  COMPANIES: 'companies',
  PROJECT_TRIGGERS: 'project_triggers',
  TENDER_TRIGGERS: 'tender_triggers',
};

export const API_FILTER_TYPES = {
  EQ_INT: 1,
  IN_INT: 2,
  NOT_IN_INT: 3,
  DATE_TIME: 4,
  DATE: 5,
  GTE_LTE_FLOAT: 6,
  GTE_LTE_DATE_TIME: 7,
  EQ_BOOLEAN_NUMBER: 8, // 0 or 1
  IN_STRING: 9,
  GTE_LTE_INT: 10,
  IN_NOT_IN_INT: 11,
  Q_STRING: 12,
  EQ_STRING: 13,
  GTE_LTE_DATE: 14,
  GTE_LTE_RANGES: 15,
  IN_NOT_IN_AGG_STRING: 16,
  EQ_BOOLEAN: 17,
};

export const WEBHOOK_EVENT_TYPES = {
  USER_PROJECT_STATUS_CHANGED: 1,
  USER_TENDER_STATUS_CHANGED: 2,
  USER_PROJECT_ADDED: 3,
  USER_TENDER_ADDED: 4,
  USER_PROJECT_UPDATED: 5,
  USER_TENDER_UPDATED: 6,
};

export const WEBHOOK_QUEUE_STATUSES = {
  QUEUING: 1,
  PROCESSING: 2,
  FINISHED: 3,
  FAILED: 4,
  properties: {
    1: { statusTagClass: 'status-tag--disabled' },
    2: { statusTagClass: 'status-tag--disabled' },
    3: { statusTagClass: 'status-tag--active' },
    4: { statusTagClass: 'status-tag--suspended' },
  },
};

export const FILE_TYPES = {
  XLS: 1,
  XLSX: 2,
};

export const FILE_STATUSES = {
  QUEUING: 1,
  PROCESSING: 2,
  GENERATED: 3,
  FAILED: 4,
  IGNORE: 5,
  properties: {
    1: { statusTagClass: 'status-tag--disabled' },
    2: { statusTagClass: 'status-tag--disabled' },
    3: { statusTagClass: 'status-tag--active' },
    4: { statusTagClass: 'status-tag--suspended' },
    5: { statusTagClass: 'status-tag--disabled' },
  },
};

export const USER_ADDONS = {
  PROJECTS: 13,
  PROJECT_MATERIALS: 4,
  TENDERS: 5,
  COMPANY_ANALYTICS: 12,
  EXPORT_TO_EXCEL: 14,
  USAGE_STATISTICS: 18,
  CRM: 36,
  WEBHOOKS: 19,
  RELEASE_NOTES: 34,
  VORTAL_CONNECT: 35,
  EXTENDED_CONTACTS: 37,
  API_CLIENT_INFO: 40,
  LIVESTAT_RSM_EDITION: 21,
  LIVESTAT_RSM_EDITION_W_EXPORT: 22,
  LIVESTAT_GYB_SALES: 23,
  LIVESTAT_GYB_PURCHASING: 24,
  LIVESTAT_GYB_MARKETING: 25,
  LIVESTAT_GYB_TENDER: 26,
  LIVESTAT_GYB_STRATEGY: 38,
  LIVESTAT_GYB_PQ: 39,
  LIVESTAT_MARKET_INSIGHT_SMALL: 28,
  LIVESTAT_MARKET_INSIGHT_MEDIUM: 29,
  LIVESTAT_MARKET_INSIGHT_LARGE: 30,
  LIVESTAT_MARKET_INSIGHT_SMALL_WITH_MATERIAL: 31,
  LIVESTAT_MARKET_INSIGHT_MEDIUM_WITH_MATERIAL: 32,
  LIVESTAT_MARKET_INSIGHT_LARGE_WITH_MATERIAL: 33,
  LIVESTAT_LIVESTAT_MATERIALS: 27,
  SHOW_UNOFF_VALUE: 45,
  EXTENDED_EXCEL: 56,
  PROJECT_MARKET_INDEX: 57,
};

export const VORTAL_COMPANY_IDENTIFIER_TYPES = {
  ORG_NR: 1,
  COMPANY_NR: 2,
  PAR_ID: 3,
  CFAR_NR: 4,
};

export const VORTAL_USER_ACTIONS = {
  LOGGED_IN: 1,
  CONTACT_CREATED: 2,
  USER_REGISTERED: 3,
  COMPANY_REGISTERED: 4,
  PROJECT_REGISTERED: 5,
  UNSUBSCRIBED: 6,
  TOKEN_SENT: 7,
  INVITE_SENT: 8,
  UPDATE_SENT: 9,
  USER_REGISTRATION_ERROR: 10,
  COMPANY_REGISTRATION_ERROR: 11,
  PROJECT_REGISTRATION_ERROR: 12,
};

export const EMAIL_PROVIDER_EVENT_TYPES = {
  PROCESSED: 1,
  CLICKED: 2,
  DELIVERED: 3,
  OPENED: 4,
  UNSUBSCRIBED: 5,
  GROUP_UNSUBSCRIBED: 6,
  GROUP_RESUBSCRIBED: 7,
  DEFERRED: 8,
  DROPPED: 9,
  BOUNCED: 10,
  BLOCKED: 11,
  SPAM_REPORTED: 12,
  properties: {
    1: { icon: 'email-event-processed' },
    2: { icon: 'email-event-clicked' },
    3: { icon: 'email-event-delivered' },
    4: { icon: 'email-event-opened' },
    5: { icon: 'email-event-unsubscribed' },
    6: { icon: 'email-event-group-resubscribed' },
    7: { icon: 'email-event-group-unsubscribed' },
    8: { icon: 'email-event-deferred' },
    9: { icon: 'email-event-dropped' },
    10: { icon: 'email-event-bounced' },
    11: { icon: 'email-event-blocked' },
    12: { icon: 'email-event-spam-reported' },
  },
};

export const NOTIFICATION_SUBJECTS = {
  USER_INVITE: 'user_invite',
  USER_MAGIC_LINK: 'user_magic_link',
  USER_RESET_PASSWORD_LINK: 'user_reset_password_link',
  USER_UPDATES: 'user_updates',
  ACTIVITY_REMINDER: 'activity_reminder',
  FILE_SENDING: 'file_sending',
  RESPONSIBLE_PERSON_ADDED: 'responsible_person_added',
  WEBHOOK_BLOCKED: 'webhook_blocked',
  FILE_GENERATED: 'file_generated',
  FINISH_REGISTRATION: 'finish_registration',
  VORTAL_CONNECT_INVITE: 'vortal_connect_invite',
  VORTAL_CONNECT_UPDATE: 'vortal_connect_update',
  VORTAL_CONNECT_TOKEN: 'vortal_connect_token',
  VORTAL_CONNECT_ERROR: 'vortal_connect_error',
  SEARCH_UPDATES: 'search_updates',
  ISTAV_USAGE_STATS: 'istav_usage_stats',
};

export const SEARCH_FIELD_TYPE = {
  SELECT: 1,
  DROPDOWN: 2,
  CUSTOM: 3,
  properties: {
    1: { component: 'search-field-select' },
    2: { component: 'search-field-dropdown' },
    3: { component: 'div' },
  },
};

export const FORM_ELEMENT_TYPES = {
  INPUT_RANGE: 1,
  DATE_RANGE: 2,
  CHECKBOXES_LIST: 3,
  CHECKBOXES_TREE: 4,
  CHECKBOXES_RELATED: 5,
  CHECKBOXES_COLUMN: 6,
  CHECKBOX: 7,
  RADIO_BUTTONS: 8,
  GEOGRAPHY: 9,
  POSTCODE_RANGE: 10,
  SELECT: 11,
  SELECT_USER: 12,
  INPUT: 13,
  AUTOCOMPLETE_LIST: 14,
  DATE_RANGE_SLIDER: 15,
  INPUT_ARRAY: 16,
  DATE_MONTH_PERIOD: 17,
  SELECT_MONTH_RANGE: 18,
  SELECT_CONTRACT_TYPES: 19,
  BUDGET_SLIDER: 20,
  SELECT_STATUSES: 21,
  SELECT_TAGS: 22,
  SEARCHABLE_LIST: 23,
  SEARCHABLE_LIST_USERS: 24,
  DATE_RANGE_PERIOD: 25,
  POSTCODE_RANGES: 26,
  BASE_INPUT: 28,
  BASE_SWITCHER: 29,
  INPUT_AUTOCOMPLETE: 30,
  INPUT_AUTOCOMPLETE_SELECTOR: 31,
  SELECT_GEOGRAPHY: 32,
  SELECT_MONTH_PERIOD: 33,
  SEARCHABLE_LIST_TAGS: 34,
  properties: {
    1: { defaultValue: { gte: '', lte: '' } },
    2: { defaultValue: null },
    3: { component: 'checkboxes-list', defaultValue: [] },
    4: { defaultValue: [] },
    5: { defaultValue: [] },
    6: { defaultValue: [] },
    7: { defaultValue: 0 },
    8: { defaultValue: null },
    9: { defaultValue: [] },
    10: { defaultValue: { gte: '', lte: '' } },
    11: { defaultValue: null },
    12: { defaultValue: null },
    13: { defaultValue: '' },
    14: { defaultValue: [] },
    15: { defaultValue: {} },
    16: { defaultValue: [] },
    17: { defaultValue: {} },
    18: { defaultValue: null },
    19: { defaultValue: [] },
    20: { component: 'budget-slider', defaultValue: [] },
    21: { defaultValue: [] },
    22: { defaultValue: [] },
    23: { defaultValue: '' },
    24: { component: 'searchable-list-users', defaultValue: '' },
    25: { defaultValue: null },
    26: { defaultValue: [] },
    27: { defaultValue: null },
    28: {
      component: 'base-input',
      defaultValue: '',
    },
    29: {
      component: 'base-switcher',
      defaultValue: false,
    },
    30: {
      component: 'input-autocomplete',
      defaultValue: [],
    },
    31: {
      component: 'input-autocomplete-selector',
      defaultValue: [],
    },
    32: {
      component: 'select-geography',
      defaultValue: [],
    },
    33: {
      component: 'select-month-period',
      defaultValue: {},
    },
    34: {
      component: 'searchable-list-tags',
      defaultValue: [],
    },
  },
};

export const SEARCH_FILTER_TYPES = {
  KEYWORDS: 1,
  MORE_OPTIONS: 2,
  BUDGET: 3,
  DATE_RANGE: 4,
  SELECT_GEOGRAPHY: 5,
  INPUT_AUTOCOMPLETE_SELECTOR: 6,
  CHECKBOXES_LIST: 7,
  SELECT_MONTH_PERIOD: 8,
  SEARCHABLE_LIST_USERS: 9,
  SEARCHABLE_LIST_TAGS: 10,
  CHECKBOXES_TREE: 11,
  CHECKBOXES_RELATED: 12,
  TENDER_SUBJECTS: 13,
  RADIO_BUTTONS: 14,
  DROPDOWN_SELECT: 15,
  SELECT_MONTH_RANGE: 16,
  DATE_RANGE_SLIDER: 17,
  // SMO
  INPUT_RANGE: 18,
  INPUT_ARRAY: 20,
  POSTCODE_RANGES: 21,
  SMO_DATE_RANGE: 22,
  SMO_CHECKBOXES_LIST: 23,
  SMO_CHECKBOXES_TREE: 24,
  CHECKBOX: 25,
  SMO_RADIO_BUTTONS: 26,
  SMO_SELECT_GEOGRAPHY: 27,
  SEARCH_PREDICTION_LIST: 28,
  SMO_DATE_RANGE_SLIDER: 29,
  SELECT_CONTRACT_TYPES: 30,
  SMO_SELECT_MONTH_RANGE: 31,
  SEARCHABLE_LIST: 32,
  DATE_RANGE_PERIOD: 34,
  SELECT_ROLES: 36,
  INPUT: 37,
  INPUT_PHONE_NUMBER: 38,
  ATTACHMENTS: 39,
  //
  DATE_RANGE_OR_OFFSET_PICKER: 40,
  DATE_OFFSET_PICKER: 41,
  MONTH_RANGE_OR_OFFSET_PICKER: 42
};

export const CUSTOM_FIELD_TYPES = {
  SINGLE_LINE_TEXT: 1,
  DESCRIPTION: 2,
  PHONE_NUMBER: 3,
  EMAIL: 4,
  DATE: 5,
  NUMBER: 6,
  PERCENT: 7,
  FORMULA: 8,
  SINGLE_SELECT: 9,
  MULTIPLE_SELECT: 10,
  CHECKBOX: 11,
  ATTACHMENT: 12,
  properties: {
    1: { icon: 'field-single-line-text' },
    2: { icon: 'field-description' },
    3: { icon: 'field-phone' },
    4: { icon: 'field-email' },
    5: { icon: 'field-date' },
    6: { icon: 'field-number' },
    7: { icon: 'field-percent' },
    8: { icon: 'field-formula' },
    9: { icon: 'field-single-select' },
    10: { icon: 'field-multiple-select' },
    11: { icon: 'field-checkbox' },
    12: { icon: 'field-attachment' },
  },
};

export const COMPONENTS_FABRIC_TYPES = {
  FORM_ELEMENT: 1,
  SEARCH_FILTER: 2,
  SEARCH_MORE_OPTIONS_FILTER: 3,
  SEARCH_CUSTOM_FIELDS: 4,
  properties: {
    1: {
      component: 'form-element-fabric',
      enums: 'FORM_ELEMENT_TYPES',
    },
    2: {
      component: 'search-filter-fabric',
      enums: 'SEARCH_FILTER_TYPES',
    },
    3: {
      component: 'search-mo-filter-fabric',
      enums: 'SEARCH_FILTER_TYPES',
    },
    4: {
      component: 'search-custom-field-fabric',
      enums: 'CUSTOM_FIELD_TYPES',
    },
  },
};

export const RESULT_BADGE_TYPES = {
  CLIENT_USERS: 1,
  DATE_MONTH_PERIOD: 2,
  BUDGET_RANGE: 3,
  STATUSES: 4,
  TAGS: 5,
  DATE_RANGE: 6,
  DATE_TIME_RANGE: 7,
  DATE_WEEKS_PERIOD: 8,
  SOURCE_TYPES: 9,
  DATE_PERIOD: 10,
  COMPANY_ROLES: 11,
  MONTH_OFFSET: 12,
};

export const FILTER_OPERATORS = {
  GTE: 'gte',
  LTE: 'lte',
  EQ: 'eq',
  IN: 'in',
  ALL: 'all',
  NOT_IN: 'not_in',
  NOT_ALL: 'not_all',
  Q: 'q',
};

export const FILTER_VALUE_TYPES = {
  INT: 'int',
  STRING: 'string',
  DATE: 'date',
  DATETIME: 'datetime',
  OFFSET_MONTHS: 'offset_months',
  OFFSET_WEEKS: 'offset_weeks',
  OFFSET_DAYS: 'offset_days',
  RANGES: 'ranges',
  CUSTOM_FIELD_NUMBER: 'cf_number',
};

export const FIELD_VALUE_STYLES = {
  CURRENCY: 1,
};

export const ATTACHMENT_FILE_TYPES = {
  DEFAULT: 'default',
  DOC: 'doc',
  DOCX: 'docx',
  XLS: 'xls',
  XLSX: 'xlsx',
  PPT: 'ppt',
  PPTX: 'pptx',
  PDF: 'pdf',
  PNG: 'png',
  JPEG: 'jpeg',
  JPG: 'jpg',
  EML: 'eml',
  MSG: 'msg',
  properties: {
    default: { icon: 'file-default', iconClass: 'file-icon--default' },
    error: { icon: 'file-error', iconClass: 'file-icon--error' },
    doc: { icon: 'file-doc', iconClass: 'file-icon--doc' },
    docx: { icon: 'file-doc', iconClass: 'file-icon--doc' },
    xls: { icon: 'file-xls', iconClass: 'file-icon--xls' },
    xlsx: { icon: 'file-xls', iconClass: 'file-icon--xls' },
    ppt: { icon: 'file-ppt', iconClass: 'file-icon--ppt' },
    pptx: { icon: 'file-ppt', iconClass: 'file-icon--ppt' },
    pdf: { icon: 'file-pdf', iconClass: 'file-icon--pdf' },
    png: { icon: 'file-png', iconClass: 'file-icon--png' },
    jpeg: { icon: 'file-jpg', iconClass: 'file-icon--jpg' },
    jpg: { icon: 'file-jpg', iconClass: 'file-icon--jpg' },
    eml: { icon: 'file-eml', iconClass: 'file-icon--eml' },
  },
};

export const USER_TRANSFER_STAGES = {
  PROJECT_TRIGGERS: 1,
  TENDER_TRIGGERS: 2,
  USER_PROJECTS: 3,
  USER_TENDERS: 4,
  USER_COMPANIES: 5,
  USER_CONTACTS: 6,
  ACTIVITIES: 7,
  NOTES: 8,
  PROJECT_RESPONSIBLES: 9,
  TENDER_RESPONSIBLES: 10,
  ACTIVITY_RESPONSIBLES: 11,
  SAVED_SEARCHES: 12,
  TAGS: 13,
  FILES: 14,
};

export const USER_TRANSFER_TASK_STATUSES = {
  PROCESSING: 1,
  DONE: 2,
  FAILED: 3,
  properties: {
    1: { statusTagClass: 'status-tag--disabled' },
    2: { statusTagClass: 'status-tag--active' },
    3: { statusTagClass: 'status-tag--suspended' },
  },
};

export const USER_TRANSFER_OPERATIONS = {
  MOVE: 1,
  COPY: 2,
};

export const PAGE_SECTIONS = {
  USER_TRIGGERS: 'user_triggers',
  CLIENT_DAUGHTERS: 'client_daughters',
  CLIENT_USERS: 'client_users',
  CLIENT_SELECTIONS: 'client_selections',
  CLIENT_CUSTOM_FIELDS: 'client_custom_fields',
  USER_EMAIL_LOGS: 'user_email_logs',
  API_CLIENT_SECRETS: 'api_client_secrets',
  USER_MYRAN_UPDATES: 'user_myran_updates',
  USER_TRANSFER_TASKS: 'user_transfer_tasks',
  CONTACT_PROJECTS: 'contact_projects',
  COMPANY_ANALYSIS: 'company_analysis',
  COMPANY_PROJECTS: 'company_projects',
  COMPANY_CONTACTS: 'company_contacts',
  ENTITY_CUSTOM_FIELDS: 'entity_custom_fields',
  PROJECT_COMPANIES: 'project_companies',
  PROJECT_MATERIALS: 'project_materials',
  PROJECT_TENDERS: 'project_tenders',
  CONTACT_TENDERS: 'contact_tenders',
  COMPANY_TENDERS: 'company_tenders',
  FIXED_ADDONS_LIST: 'fixed_addons_list',
  TENDER_TENDERS: 'tender_tenders',
  CLIENT_SUBSCRIPTIONS: 'client_subscriptions',
  CLIENT_FOLDERS: 'client_folders',
  SUBSCRIPTION_USERS: 'subscription_users',
  USER_SUBSCRIPTIONS: 'user_subscriptions',
  SUBSCRIPTION_MYRAN_UPDATES: 'subscription_myran_updates',
};

export const USER_TRANSFER_TASK_START_TYPES = {
  AUTO: 1,
  MANUAL: 2,
};

export const PM_ENTITY_TYPES = {
  PROJECT: 1,
  COMPANY: 2,
  CONTACT: 3,
  EMPLOYEE: 4,
  DOCUMENT: 5,
  ACTIVITY: 6,
};

export const VALUE_TYPES = {
  int: 'int',
  decimal: 'decimal',
  datetime: 'datetime',
  string: 'string',
  string_list: 'string list',
  string_lookup: 'string lookup',
  string_multi_lookup: 'string multi lookup',
  file_multi_lookup: 'file multi lookup',
  bool: 'bool',
};

export const SUBSCRIPTION_TYPES = {
  ORDER_PAID: 1,
  TEST: 2,
  K: 3,
  1: 4,
  properties: {
    1: { name: 'Order (paid)' },
    2: { name: 'Test' },
    3: { name: 'K' },
    4: { name: '1' },
  },
};

export const MYRAN_SUBSCRIPTION_TYPES = {
  O: 1,
  T: 2,
  K: 3,
  1: 4,
  properties: {
    1: { name: 'O' },
    2: { name: 'T' },
    3: { name: 'K' },
    4: { name: 1 },
  },
};

export const MODULE_VISIBILITY = {
  SYSTEM: 1,
  PUBLIC: 2,
};

export const PRODUCTS = {
  SMART: 1,
  ANALYS: 2,
  SBS: 3,
};

export const RESPONSIBLE_USER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  UNKNOWN: 'unknown',
};

export const ENTITY_UPDATES_KEYS = {
  PROJECT_COMPANY_ROLE_REGISTERED: 'PROJECT_COMPANY_ROLE_REGISTERED',
};

export const LOGIN_VIEW_STATE = {
  LOGIN: 'login',
  FORGOT: 'forgot',
};

export const LOGIN_TYPE = {
  PASSWORD: 'password',
  CORPORATE: 'corporate',
};

export const SSO_PROVIDERS = {
  SHARP: 1,
  AZURE: 2,
  GOOGLE: 3,
  properties: {
    1: { name: 'Sharp' },
    2: { name: 'Azure' },
    3: { name: 'Google' },
  },
};

export const SSO_AZURE_TOKEN_VALIDATION_LEVELS = {
  TID: 1,
  TID_PLUS_OID: 2,
  properties: {
    1: { name: 'TID' },
    2: { name: 'TID+OID' },
  },
};

export const OAUTH_LOGIN_STATES = {
  LOGGED: 'logged',
  LOGIN: 'login',
};

export const DASHBOARD_WIDGET_TYPES = {
  SMART_MARKET_TRENDS: 1,
  SMART_NEWS: 2,
  SMART_PROJECT_EXPLORER: 3,
  SMART_FIND_ME: 4,
  SMART_CUSTOM_NOTIFICATIONS: 5,
  SMART_SAVE_SEARCH: 6,
};

export const STAT_DASHBOARD_WIDGET_VIEWS = {
  SMART_PROJECT_EXPLORER_HOT: 1,
  SMART_PROJECT_EXPLORER_SIMILAR: 2,
  SMART_PROJECT_EXPLORER_BIGGEST: 3,
  SMART_NEWS_NEW: 4,
  SMART_NEWS_EVENT_FILTER: 5,
  SMART_NEWS_WORKING_WITH: 6,
  SMART_NEWS_MONITORING: 7,
};

export const STAT_DASHBOARD_WIDGET_BUTTONS = {
  SHOW_MORE: 1,
};

export const STAT_LOG_ACTIONS = {
  VIEW: 1,
  ADD_TO_INBOX: 2,
  ASSIGN: 3,
  POST: 4,
  PATCH: 5,
  PUT: 6,
  DELETE: 7,
  DOWNLOAD: 8,
  SEND: 9,
  LOG_IN: 10,
  LOG_OUT: 11,
  CLICK_PHONE: 12,
  CLICK_EMAIL: 13,
  LIST: 14,
  USER_ENTITY_STATUS_CHANGED: 15,
  USER_ENTITY_ADDED: 16,
  USER_ENTITY_UPDATED: 17,
  USER_ENTITY_DELETED: 18,
  ENTITY_CLICKED: 19,
  BTN_CLICKED: 20,
  VIEW_CHANGED: 21,
  FILTER_CHANGED: 22,
};

export const MODULE_TAGS = {
  SUBSCRIPTION: 'subscription',
  SUBSCRIPTION_FIXED: 'subscription_fixed',
  USER_PROFILE: 'user_profile',
  CLIENT_FIXED: 'client_fixed',
  CLIENT_USER_FIXED: 'client_user_fixed',
};

export default {
  ENTITY_TYPES,
  SOURCE_TYPES,
  VISIBILITY,
  GEO_ACCURACY,
  DATE_ACCURACY,
  MENU_TYPES,
  ACC_SETTING_TYPE,
  USER_STATUSES,
  USER_TOKEN_TYPES,
  CLIENT_ROLE_TYPES,
  LICENSE_QUOTA_TYPES,
  CONTACT_TYPES,
  ADDRESS_TYPES,
  INVITE_STATUSES,
  NOTIF_GATEWAYS,
  NOTIF_QUEUE_STATUES,
  SENDING_MODES,
  NOTIF_STATUSES,
  USER_PROJECT_STATUSES,
  USER_TENDER_STATUSES,
  ACTIVITY_STATUSES,
  ACTIVITY_TYPES,
  ADMINISTRATIVE_UNIT_TYPES,
  CLIENT_COMPANY_FOLDER_TYPES,
  COMMUNICATION_TYPES,
  RESIDENCE_FORMS,
  LIST_TYPES,
  TENDER_SUBJECTS,
  PROJECT_STAGES,
  COUNTRIES,
  TENDER_DATE_TYPES,
  CONTRACT_TYPES,
  CURRENCIES,
  ECOCERTIFIED_TYPES,
  CONTACT_ALL_ROLES_INDEXES,
  ENV_MODES,
  USER_COMPANY_FOLDERS,
  SERVER_ERROR_CODES,
  SEARCHABLE_LIST_TYPES,
  LICENSES,
  TRIGGER_TYPES,
  DICT_SECTION_TYPES,
  ACTIVITY_NOTES_TYPES,
  NOTE_ACTIONS,
  ACTIVITY_ACTIONS,
  ENTITY_LIST_VIEWS,
  ENTITY_LIST_PAGINATION_TYPES,
  ENTITY_LIST_LOAD_TYPES,
  CRAFT_STATES,
  DOC_ACCESS_TYPES,
  USER_IMPORT_STATUSES,
  TABLE_FIELD_TYPES,
  QUERY_DATA_TYPES,
  ACCOUNT_ACCESS_TYPES,
  LOOKUP_SECTION_TYPES,
  LANGUAGES,
  US_DATE_RANGE_TYPES,
  KEYCODE,
  USER_STATUS_STEPS,
  API_FILTER_TYPES,
  WEBHOOK_EVENT_TYPES,
  WEBHOOK_QUEUE_STATUSES,
  FILE_TYPES,
  FILE_STATUSES,
  USER_ADDONS,
  VORTAL_COMPANY_IDENTIFIER_TYPES,
  VORTAL_USER_ACTIONS,
  EMAIL_PROVIDER_EVENT_TYPES,
  NOTIFICATION_SUBJECTS,
  SEARCH_FIELD_TYPE,
  FORM_ELEMENT_TYPES,
  SEARCH_FILTER_TYPES,
  CUSTOM_FIELD_TYPES,
  COMPONENTS_FABRIC_TYPES,
  RESULT_BADGE_TYPES,
  FILTER_OPERATORS,
  FILTER_VALUE_TYPES,
  FIELD_VALUE_STYLES,
  ATTACHMENT_FILE_TYPES,
  USER_TRANSFER_STAGES,
  USER_TRANSFER_TASK_STATUSES,
  USER_TRANSFER_OPERATIONS,
  PAGE_SECTIONS,
  CLIENT_COMPANY_FOLDERS,
  USER_TRANSFER_TASK_START_TYPES,
  SUBSCRIPTION_TYPES,
  MYRAN_SUBSCRIPTION_TYPES,
  MODULE_VISIBILITY,
  RESPONSIBLE_USER_STATUS,
  ENTITY_UPDATES_KEYS,
  LOGIN_TYPE,
  LOGIN_VIEW_STATE,
  SSO_PROVIDERS,
  SSO_AZURE_TOKEN_VALIDATION_LEVELS,
};
